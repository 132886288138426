<template>
  <!-- 报工审核 -->
  <div>
    <div class="examine_main">
      <van-nav-bar left-text="报工审核" left-arrow @click-left="onClickLeft" :fixed="true">
        <template #right>
          <div class="date" @click="checkExDate">
            <i class="fa fa-calendar"></i>
            {{ bd }}
            <span style="margin:0 5px;font-size:12px;color: #333">至</span>
            {{ ed }}
            <div class="faRight">
              <i class="fa fa-sort-desc"></i>
            </div>
          </div>
        </template>
      </van-nav-bar>
      <div class="content">
        <!-- 待审核 -->
        <div v-if="isWait" class="Audit_content">
          <div class="tools_row">
            <div>
              <van-field readonly clickable v-model="jxtype" placeholder="选择计产类别" right-icon="arrow-down"
                @click="showJcTypePicker = true" />
            </div>
            <van-button round type="info" size="small" :icon="require('@assets/img/job_examine02.png')"
              @click="batchExamine">批量审核</van-button>
          </div>
          <van-search v-model="searchStr" shape="round" placeholder="单号/订单号/工序/机台" @search="onSearch" />
          <div class="table_content">
            <div class="tools_title">
              <div class="left">
                <img :src="require('@/assets/img/job_examine05.png')" alt />
                <div>待审核明细</div>
              </div>
              <div class="right">
                <img :src="require('@/assets/img/job_examine06.png')" alt />
                <span class="quan" @click="checkAll(true)">全选</span>
                <span class="fan" @click="checkAll(false)">反选</span>
              </div>
            </div>
            <div class="table_box" style="overflow: auto;">
              <table ref="table" class="table">
                <!-- 表头 -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList" :key="index_th">
                    <div class="title"
                      :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                  <th>
                    <div class="title" style="width:50px;">
                      操作
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <tr class="cont" v-for="(item_tr, index_tr) in auditTableData" :key="index_tr"
                  @click.stop="changeAuditDataByTd(item_tr)">
                  <td v-for="(item_td, index_td) in auditThList" :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box" v-if="item_td.datatype == 'checkbox'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                      </div>
                      <div class="input_box" v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]" :placeholder="item_td.chnname" />
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="edit_td">
                      <van-button type="info" size="mini" @click.stop="editAudit(item_tr)">修改</van-button>
                    </div>
                  </td>
                </tr>
                <tr class="nodata" v-if="auditTableData.length == 0">
                  <td align="center" colspan="9">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- 历史审核 -->
        <div v-else class="his_content">
          <van-search v-model="searchStr" shape="round" placeholder="单号/订单号/工序/机台" @search="onSearch" />
          <div class="table_content">
            <div class="tools_title">
              <div class="left">
                <img :src="require('@/assets/img/job_examine05.png')" alt />
                <div>审核历史</div>
              </div>
              <div class="right">
                <van-button type="info" size="small" @click="updateHisRp">修改报工</van-button>
              </div>
            </div>
            <div class="table_box" style="overflow: auto;">
              <table ref="table" class="table">
                <!-- 表头 -->
                <tr class="top">
                  <th v-for="(item_th, index_th) in auditThList" :key="index_th">
                    <div class="title"
                      :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                      {{ item_th.chnname }}
                    </div>
                  </th>
                </tr>
                <!--展示列表数据 -->
                <tr class="cont" v-for="(item_tr, index_tr) in hisTableData" :key="index_tr"
                  @click.stop="changeHisDataByTd(item_tr)">
                  <td v-for="(item_td, index_td) in auditThList" :key="index_td">
                    <div class="comm_box">
                      <div class="boolean_box" v-if="item_td.datatype == 'checkbox'">
                        <van-checkbox v-model="item_tr[item_td.fieldname]" @click="changeHisData(item_tr)"></van-checkbox>
                      </div>
                      <div class="input_box" v-else-if="item_td.datatype == 'num'">
                        <van-field v-model="item_tr[item_td.fieldname]" :placeholder="item_td.chnname" />
                      </div>
                      <div v-else>{{ item_tr[item_td.fieldname] }}</div>
                    </div>
                  </td>
                </tr>
                <tr class="nodata" v-if="hisTableData.length == 0">
                  <td align="center" colspan="8">暂无数据</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="f_btn" @click="checkFoot('to')">
          <img :src="require(isWait ? '@/assets/img/job_examine03.png' : '@/assets/img/job_examine04.png')" alt />
          <div>待审核</div>
        </div>
        <div class="f_btn" @click="checkFoot()">
          <img :src="require(isWait ? '@/assets/img/job_booking06.png' : '@/assets/img/job_booking05.png')" alt />
          <div>审核历史</div>
        </div>
      </div>
    </div>
    <!-- 选择日期日历弹窗 -->
    <van-calendar v-model="showCalendar" type="range" allow-same-day :min-date="minDate" :max-date="maxDate"
      @confirm="onConfirmCal" />
    <!-- 计产类别下拉弹窗 -->
    <van-popup v-model="showJcTypePicker" round position="bottom">
      <van-picker show-toolbar :columns="JcTypeColumns" value-key="jxtype" :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')" @cancel="showJcTypePicker = false" @confirm="onConfirmJc" />
    </van-popup>
    <!-- 修改待审核报工弹窗 -->
    <van-dialog v-model="showAuditUpdate" class="updateHis_report_dia" show-cancel-button
      :confirm-button-text="$t('module.Save')" confirm-button-color='#0782F5' :beforeClose="updateAuditReport">
      <template #title>
        <div class="title">修改合格数量</div>
        <div class="desc">请谨慎操作</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly label="工序" v-model="updateAuditItem.processname" />
          <van-field readonly label="姓名" v-model="updateAuditItem.empname" />
          <van-field readonly label="机台" v-model="updateAuditItem.machinename" />
          <van-field readonly label="派工数" v-model="updateAuditItem.pgnum" />
          <van-field label="报工数" readonly v-model="updateAuditItem.bgnum" />
          <van-field label="合格数量" type="number" placeholder="输入合格数量" v-model="updateAuditItem.hgnum" />
        </van-form>
      </div>
    </van-dialog>

    <!-- 批量审核提示弹窗 -->
    <van-dialog v-model="showbatchHintDia" class="batchHint_Dia" show-cancel-button
      :confirm-button-text="$t('module.confirm')" confirm-button-color='#F63400' :beforeClose="batchHintCallBack">
      <template #title>
        <img :src="require('@/assets/img/job_examine07.png')" alt />
        <div class="title">批量审核提示</div>
      </template>
      <div class="content">
        <div>{{ '您当前已选择' + checkExDateLength + '条记录，' }}</div>
        <div>是否进行批量审核操作？</div>
      </div>
    </van-dialog>

    <!-- 修改历史审核报工弹窗 -->
    <van-dialog v-model="showUpdateNum" class="updateHis_report_dia" show-cancel-button :confirm-button-text="'提交审核'"
      confirm-button-color='#0782F5' :beforeClose="updateHisReport">
      <template #title>
        <div class="title">修改合格数量</div>
        <div class="desc">请谨慎操作</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly label="工序" v-model="updateHisItem.processname" />
          <van-field readonly label="机台" v-model="updateHisItem.machinename" />
          <van-field readonly label="派工数" v-model="updateHisItem.pgnum" />
          <van-field readonly label="报工数" v-model="updateHisItem.bgnum" />
          <van-field label="合格数量" type="number" placeholder="输入合格数量" v-model="updateHisItem.hgnum" />
        </van-form>
      </div>
    </van-dialog>

    <!-- 接口返回提示信息弹窗 -->
    <van-dialog v-model="showHintDia" class="Automatic_report hint_dialog" :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess ? '@/assets/img/job_booking11.png' : '@/assets/img/job_booking10.png')" alt />
        <div class="hint_msg">{{ hintMessage }}</div>
        <van-button type="info" size="small" round @click="showHintDia = false">好的</van-button>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import { Toast, Notify } from "vant";
import { getDefaultJxType, getForCheckBgList, batchCheckBgList, getHisHasCheckList, saveBgNum, saveForCheckHgNum } from '@api/wxjj.js'
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      isWait: true,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      exDate: this.formatDate(new Date(), 'yyyy-MM-dd'),
      bd: this.formatDate(new Date(), 'yyyy-MM-dd'),
      ed: this.formatDate(new Date(), 'yyyy-MM-dd'),
      showCalendar: false,
      jxtype: '',
      showJcTypePicker: false,
      JcTypeColumns: [],
      auditThList: [
        {
          fieldname: "checked",
          chnname: "",
          gridwith: 10,
          datatype: 'checkbox'
        },
        {
          fieldname: "processname",
          chnname: "工序",
          gridwith: 40,
          datatype: 'str'
        },
        {
          fieldname: "empname",
          chnname: "姓名",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "partcode",
          chnname: "工件编号",
          gridwith: null,
          datatype: 'str'
        },
        {
          fieldname: "partname",
          chnname: "工件名称",
          gridwith: null,
          datatype: 'str'
        },
        {
          fieldname: "pgnum",
          chnname: "派工数量",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "bgnum",
          chnname: "报工数量",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "hgnum",
          chnname: "合格数量",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "yymmdd",
          chnname: "报工日期",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "orderid",
          chnname: "订单号",
          gridwith: null,
          datatype: 'str'
        }
      ],
      auditTableData: [], // 待审批表格数据
      initAuditTableData: [],
      showbatchHintDia: false, // 批量审批提示弹窗
      batchExData: [],
      checkExDateLength: 0,
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchStr: '',
      hisTableData: [], // 审批历史表格数据
      initHisTableData: [], // 审批历史原始数据
      updateHisItem: {
        processname: '',
        machinename: '',
        pgnum: '',
        bgnum: '',
        hgnum: ''
      }, // 要修改的记录
      showUpdateNum: false,
      showAuditUpdate: false,
      updateAuditItem: {}

    }
  },
  watch: {
    // exDate (n) {
    //   if (this.isWait)      {
    //     this.getForCheckBgListData()
    //   } else      {
    //     this.getHisHasCheckListData()
    //   }
    // },
    jxtype (n) {
      if (this.isWait)      {
        this.getForCheckBgListData()
      } else      {
        this.getHisHasCheckListData()
      }
    }
  },
  created () {
    this.getDefaultJxTypeData()
  },
  methods: {
    // 回退
    onClickLeft () {
      this.$router.push('/home')
    },
    // 修改待审核记录弹窗回调
    updateAuditReport (action, done) {
      if (action == 'confirm')      {
        let params = {
          autoid: this.updateAuditItem.autoid,
          hgnum: this.updateAuditItem.hgnum,
          jxtype: this.jxtype,
          username: this.userInfo.username
        }
        saveForCheckHgNum(params).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '修改合格数量成功！'
            // 刷新表格
            this.getForCheckBgListData()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else      {
        done();
      }
    },
    // 修改待审核记录
    editAudit (item) {
      console.log(item, 'item');
      this.showAuditUpdate = true
      this.updateAuditItem = { ...this.updateAuditItem, ...item }
    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.auditTableData.forEach(ele => {
        if (val.autoid == ele.autoid)        {
          ele.checked = !ele.checked
        }
      });
    },
    // 修改历史数据弹窗回调
    updateHisReport (action, done) {
      if (action == 'confirm')      {
        let params = {
          autoid: this.updateHisItem.autoid,
          hgnum: this.updateHisItem.hgnum,
          jxtype: this.jxtype,
          username: this.userInfo.username
        }
        saveBgNum(params).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '修改合格数量提交成功！'
            // 刷新表格
            this.getHisHasCheckListData()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else      {
        done();
      }
    },
    // 历史--修改报工
    updateHisRp () {
      let upAry = this.hisTableData.filter(it => { return it.checked })
      if (upAry.length > 0)      {
        this.showUpdateNum = true
        this.updateHisItem = { ...this.updateHisItem, ...upAry[0] }
      } else      {
        Toast.fail('请选择一条记录！');
      }
    },
    // 历史搜索方法
    onSearch () {
      if (this.searchStr)      {
        if (this.isWait)        {
          this.auditTableData = this.initAuditTableData.filter(e => {
            const processname = e.processname ? e.processname : ''
            const machinename = e.machinename ? e.machinename : ''
            const listno = e.listno ? e.listno : ''
            const orderid = e.orderid ? e.orderid : ''
            return (
              processname.indexOf(this.searchStr) > -1 ||
              machinename.indexOf(this.searchStr) > -1 ||
              listno.indexOf(this.searchStr) > -1 ||
              orderid.indexOf(this.searchStr) > -1
            )
          })
        } else        {
          this.hisTableData = this.initHisTableData.filter(e => {
            const processname = e.processname ? e.processname : ''
            const machinename = e.machinename ? e.machinename : ''
            const listno = e.listno ? e.listno : ''
            const orderid = e.orderid ? e.orderid : ''
            return (
              processname.indexOf(this.searchStr) > -1 ||
              machinename.indexOf(this.searchStr) > -1 ||
              listno.indexOf(this.searchStr) > -1 ||
              orderid.indexOf(this.searchStr) > -1
            )
          })
        }

      } else      {
        if (this.isWait)        {
          this.getForCheckBgListData()
        } else        {
          this.getHisHasCheckListData()
        }
      }
    },
    // 历史审核表格的行选择事件
    changeHisDataByTd (val) {
      this.hisTableData.forEach(ele => {
        if (val.autoid == ele.autoid)        {
          ele.checked = !ele.checked
        } else        {
          ele.checked = false
        }
      });
    },
    // 历史审核表格的选择事件
    changeHisData (val) {
      this.hisTableData.forEach(ele => {
        if (val.autoid !== ele.autoid)        {
          ele.checked = false
        }
      });
    },
    // 获取历史审核记录
    getHisHasCheckListData () {
      getHisHasCheckList({
        // yymmdd: this.exDate,
        bd: this.bd,
        ed: this.ed,
        jxtype: this.jxtype,
        username: this.userInfo.username
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        result.forEach(ele => {
          ele.checked = false
        });
        this.hisTableData = result
        this.initHisTableData = result
      })
    },
    // 获取待审核数据
    getForCheckBgListData () {
      getForCheckBgList({
        bd: this.bd,
        ed: this.ed,
        jxtype: this.jxtype,
        username: this.userInfo.username
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        result.forEach(ele => {
          ele.checked = false
        });
        this.auditTableData = result
        this.initAuditTableData = result
      })
    },
    // 选择计产类别回调
    onConfirmJc (value) {
      this.jxtype = value.jxtype
      this.showJcTypePicker = false
      this.getForCheckBgListData()
    },
    // 获取默认计产类别
    getDefaultJxTypeData () {
      getDefaultJxType({
        username: this.userInfo.username
      }).then(res => {
        this.JcTypeColumns = res.data
        this.jxtype = res.data[0] ? res.data[0].jxtype : ''
      })
    },
    // 待审批表格的全选/反选
    checkAll (val) {
      if (val)      { // 全选
        this.auditTableData.forEach(ele => {
          ele.checked = true
        })
      } else      { // 反选
        this.auditTableData.forEach(ele => {
          ele.checked = false
        })
      }
      this.$forceUpdate()
    },
    // 批量审核提示弹窗回调
    batchHintCallBack (action, done) {
      if (action == 'confirm')      {
        batchCheckBgList({
          jxtype: this.jxtype,
          autoidlist: this.batchExData.map(it => { return it.autoid }),
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '批量审核提交成功'
            // 刷新表格
            this.getForCheckBgListData()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else      {
        done();
      }
    },
    // 批量审核
    batchExamine () {
      this.batchExData = this.auditTableData.filter(it => { return it.checked })
      if (this.batchExData.length > 0)      {
        this.showbatchHintDia = true
        this.checkExDateLength = this.batchExData.length
      } else      {
        Toast.fail('请选择记录！');
      }
    },
    // 右上角选择日期
    checkExDate () {
      this.showCalendar = true
    },
    // 日历日期选择回调
    onConfirmCal (date) {
      // this.exDate = this.formatDate(date, 'yyyy-MM-dd')
      this.bd = this.formatDate(date[0], 'yyyy-MM-dd');
      this.ed = this.formatDate(date[1], 'yyyy-MM-dd');
      this.showCalendar = false;
      if (this.isWait)      {
        this.getForCheckBgListData()
      } else      {
        this.getHisHasCheckListData()
      }
    },
    // 底部按钮切换
    checkFoot (val) {
      if (val == 'to')      { // 待审批
        this.isWait = true
        this.getForCheckBgListData()
      } else      { // 历史
        this.getHisHasCheckListData()
        this.isWait = false
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else      {
        return ''
      }
    }
  }

}
</script>

<style scoped lang="less">
.updateHis_report_dia {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_examine09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.hint_dialog {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_examine08.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .content {
    text-align: center;

    img {
      width: 160px;
      height: 160px;
    }

    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }

    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}

.batchHint_Dia {
  background: linear-gradient(0deg, #ffffff 56.00000000000001%, #ffaea3 100%);

  /deep/.van-dialog__header {
    padding: 20px 0;
    color: #08306b;

    img {
      width: 200px;
    }

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .van-dialog__content {
    .content {
      margin-bottom: 40px;
      text-align: center;
      font-size: 28px;
      color: #7f7f7f;
    }
  }
}

.examine_main {
  height: 100vh;
  background-image: url("~@/assets/img/job_examine01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;

  .van-nav-bar {
    height: 92px;
    background: #bcdfff;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }

      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }

    /deep/.van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }

    /deep/.van-nav-bar__right {
      .date {
        font-size: 24px;
        display: flex;
        align-items: center;

        .fa-calendar {
          margin-right: 6px;
        }

        .faRight {
          i {
            margin-left: 6px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .van-hairline--bottom::after {
    border: unset;
  }

  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;

    .Audit_content {
      .tools_row {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .van-cell {
          border: 2px solid #d2d2d2;
          border-radius: 10px;
          padding: 10px 20px;
        }

        .van-button {
          width: 200px;
          font-size: 28px;

          .van-icon {
            font-size: 28px;
          }
        }
      }

      .van-search {
        background-color: unset;
        // padding-top: 0;
        padding-bottom: 0;
        // max-width: 45%;
      }
    }

    .his_content {
      .van-search {
        background-color: unset;
        padding-bottom: 0;
      }
    }

    .table_content {
      margin: 20px;
      padding: 10px;
      border-radius: 10px;
      background: #ffffff;

      .tools_title {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          font-size: 32px;
          font-weight: bold;

          img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }

        .right {
          display: flex;
          align-items: center;
          font-size: 28px;

          img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }

          .quan {
            padding-right: 12px;
            border-right: 2px solid #d2d2d2;
            margin-right: 12px;
          }

          .van-button {
            width: 180px;
            border-radius: 10px;
          }
        }
      }

      .table_box {
        .table {
          width: 100%;
          border-spacing: 0px;

          tr {
            td:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }

            th,
            td {
              padding: 6px 10px;
              text-align: center;
              border-bottom: 2px solid #dcdfe6;
              border-right: 2px solid #dcdfe6;

              .comm_box {
                display: flex;
                justify-content: center;

                .boolean_box {
                  .van-checkbox {
                    justify-content: center;

                    /deep/.van-checkbox__icon--checked {
                      .van-icon {
                        color: #fff !important;
                        background-color: #1989fa !important;
                        border-color: #1989fa !important;
                      }
                    }

                    /deep/.van-checkbox__icon--disabled .van-icon {
                      background-color: #fff;
                    }
                  }
                }

                .input_box {
                  width: 150px;

                  .van-cell {
                    font-size: 24px;
                    border: 2px solid #d2d2d2;
                    border-radius: 10px;
                    padding: 8px 16px;
                  }
                }
              }
            }
          }

          .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;

            th:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }

            th {
              border-top: 2px solid #dcdfe6;
            }
          }

          .cont {
            height: 64px;
            font-size: 24px;
          }

          .cont:nth-child(2n-1) {
            background: #f5f5f5;
          }

          .nodata {
            td {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  .footer {
    height: 120px;
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 28px;
    display: flex;

    .f_btn {
      flex: 1;
      text-align: center;
      padding-top: 10px;

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>